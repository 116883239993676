import React from "react";
import loadable from "@loadable/component";

const Layout = loadable(() => import("@common/Layout/Layout"));
const ErrorPage = loadable(() => import("@components/ErrorPage/ErrorPage"));

const NotFoundPage = ({ path }) => (
    <Layout path={path}>
        <ErrorPage
            title="404 Error"
            firstDescription="We apologize, but this page was not found."
            secondDescription="Try again later."
            buttonText="Back to home page"
            navigateTo="/"
        />
    </Layout>
);

export default NotFoundPage;
